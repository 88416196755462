<template>
  <div>
    <tab-bar-wrapper :columns="columns">
    </tab-bar-wrapper>
    <div id="grid-container" class="grid-extended">
      <grid-wrapper :editableNodes="editableNodes"
                    :contextMenuItems="contextMenuItems"
                    :query="query"
                    :editedData="editedData"
                    :commentList="commentList"
                    :gridMode="gridMode"
                    @ready="ready">
      </grid-wrapper>
    </div>
    <action-bar-wrapper :commentList="commentList"
                        :buttonList="buttonList"
                        @save="save"
                        @cancel="cancel">
    </action-bar-wrapper>
    <modals-container/>
  </div>
</template>

<script>
import TabBarWrapper from '@/components/wrappers/tabBarWrapper.vue'
import ActionBarWrapper from '@/components/wrappers/actionBarWrapper.vue'
import GridWrapper from '@/components/wrappers/gridWrapper.vue'
// import moment from 'moment'
export default {
  name: 'logs',
  components: {
    'tab-bar-wrapper': TabBarWrapper,
    'action-bar-wrapper': ActionBarWrapper,
    'grid-wrapper': GridWrapper
  },
  data () {
    return {
      commentList: [
        { name: 'comments', label: 'Internal', text: 'Employee Logging Comment Zone', editable: true }
      ],

      logData: null,

      // TODO: need to hash button list out
      buttonList: {
        printButton: [
          'Labels'
        ]
      },

      editableNodes: [],
      gridOptions: null,
      gapi: null,
      capi: null,
      gridMode: 'read',

      // TODO: need to hash this out
      contextMenuItems: (params) => {
        return [
          {
            name: 'Test Date Method',
            action: () => {
              this.testingMethod(params)
            },
            cssClasses: ['bold']
          },
          'separator',
          'copy',
          'export'
        ]
      }
    }
  },
  methods: {
    save (params) {
      // TODO:
    },

    cancel (params) {
      // TODO:
    },

    ready (params) {
      this.gapi = params.api
      this.capi = params.columnApi
      this.gridOptions = params
    },

    testingMethod (params) {

    }
  }
}
</script>
